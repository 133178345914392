import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";

function Term() {
  return (
    <>
      <Navbar />
      <Header />
      <div>
        <div id="primary" className="content-area">
          <div className="entry-content" itemProp="articleBody">
            <h2>1. KHAI BÁO KHI SỬ DỤNG</h2>
            <p>
              Tất cả nội dung bạn xem được trên trang web nhằm phục vụ nhu cầu
              thông tin của bạn và giúp bạn lựa chọn dịch vụ. Vì thế, bạn được
              sử dụng trang web mà không cần cung cấp bất kỳ thông tin cá nhân
              nào. Tuy nhiên, trong một số trường hợp, khi liên quan đến việc
              đăng ký dịch vụ, chúng tôi có thể yêu cầu bạn cung cấp thông tin
              cá nhân.
            </p>
            <h2>2. THÔNG TIN CÁ NHÂN DO BẠN CUNG CẤP</h2>
            <p>
              Khi bạn cung cấp các thông tin cần thiết, chúng tôi sẽ sử dụng
              chúng để đáp ứng yêu cầu của bạn, hoặc chúng tôi có thể liên lạc
              với bạn qua thư từ, email, tin nhắn hay điện thoại; hoặc tuân theo
              luật định hiện hành, sử dụng các phương tiện trên để giới thiệu
              đến bạn những dịch vụ mới từ TTS Extension. Khi cung cấp thông tin
              cho chúng tôi qua trang web này, bạn hiểu rõ và chấp thuận việc
              thu thập, sử dụng và tiết lộ những thông tin cá nhân nêu trên cho
              một số mục đích được quy định trong trang này.
              <br />
              Bạn hoàn toàn đồng ý và chấp thuận rằng những thông tin cá nhân
              bạn cung cấp khi sử dụng trang web có thể được bảo lưu tại trụ sở
              hay chi nhánh của TTS Extension, có thể được lưu trữ tại một số
              máy chủ hiện có hay chưa biết trước cho mục đích vận hành và phát
              triển trang web cũng như các dịch vụ của TTS Extension.
              <br />
              Nếu bạn ứng tuyển vào một vị trí công việc, gửi phản hồi hoặc đề
              nghị hợp tác qua trang web, chúng tôi có thể liên hệ với bạn để có
              những thông tin bổ sung cần thiết cho việc xử lý hoặc hoàn thành
              yêu cầu, đề nghị của bạn. Tuy nhiên, chúng tôi sẽ không cung cấp
              thông tin này cho một bên thứ ba mà không được sự cho phép của
              bạn, trừ trường hợp bị pháp luật hiện hành bắt buộc.
              <br />
              TTS Extension cam kết bảo vệ sự riêng tư của bạn, không mua bán
              thông tin cá nhân của bạn cho các công ty khác mà không được sự
              đồng ý của bạn.
            </p>
            <h2>3. CUNG CẤP CHO CÁC ĐƠN VỊ KHÁC</h2>
            <p>
              Chúng tôi có thể sử dụng một số đơn vị khác để cung cấp dịch vụ
              cho bạn. Chúng tôi cũng cần trao đổi thông tin cá nhân của bạn đến
              các đơn vị này để họ hoàn thành yêu cầu của bạn. Những đơn vị này
              cũng không được phép sử dụng thông tin cá nhân của bạn cho các mục
              đích khác và chúng tôi đồng thời yêu cầu họ tuân theo quy định bảo
              mật khi tiến hành cung cấp dịch vụ.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Term;
