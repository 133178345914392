import { Modal, Spin } from "antd";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  .ant-modal-content {
    background-color: #f0f0f0; /* Màu nền */
    border-radius: 10px; /* Bo tròn góc */
  }
`;
const ShowModal = ({ sheetId }) => {
  return (
    <CustomModal
      open="true"
      footer={null}
      className="modal-container"
      closable={false}
    >
      {sheetId ? (
        <div>
          <span>ID Goole Sheets của bạn: </span>
          <br></br>
          <span>{sheetId}</span>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Spin />
          <span>Đang tạo Sheets cho bạn</span>
        </div>
      )}
    </CustomModal>
  );
};

ShowModal.displayName = "ShowModal";
export default ShowModal;
