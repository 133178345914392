import React from "react";

function About() {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#1a1a1a",
        color: "#000",
        margin: "0",
        padding: "0",
      }}
    >
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#000",
        }}
      >
        <img
          src="your-logo.png"
          alt="App Logo"
          style={{ width: "50px", borderRadius: "50%" }}
        />
        <a
          href="#"
          style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
        >
          Lets Connect
        </a>
      </header>
      <main
        style={{
          maxWidth: "800px",
          margin: "50px auto",
          padding: "20px",
          lineHeight: "1.6",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "2rem",
            marginBottom: "20px",
            color: "#000",
          }}
        >
          Google OAuth2 Homepage
        </h1>
        <hr
          style={{
            border: "0",
            height: "1px",
            background: "#333",
            margin: "30px 0",
          }}
        />

        <h2 style={{ marginTop: "40px", fontSize: "1.5rem", color: "#000" }}>
          Accurately represents your app's identity
        </h2>
        <p style={{ color: "#000" }}>
          The tool fetches order information from TikTok Shop. The TTS Extension
          helps you automatically store order details from your own shop.
        </p>

        <h2 style={{ marginTop: "40px", fontSize: "1.5rem", color: "#000" }}>
          What will this app do with user data?
        </h2>
        <p style={{ color: "#000" }}>
          The only user data received is: name, email, and profile picture.
          These will be saved and re-displayed to the user. The email will be
          used to identify the user. The name and profile picture will be used
          as the name and profile picture of your account on Advanciti App. In
          addition, the app requires permissions to interact with your Google
          Drive and Google Sheets. Specifically, the app can access, create,
          edit, and delete Google Drive files and Google Sheets spreadsheets
          that you use with the app. These actions are limited to only the
          specific files associated with your account, ensuring that other files
          in your Google Drive are not affected.
        </p>

        <h2 style={{ marginTop: "40px", fontSize: "1.5rem", color: "#000" }}>
          How does this app enhance user functionality?
        </h2>
        <p style={{ color: "#000" }}>
          This app enhances user functionality by allowing users to create
          Google Sheets files and save your data directly into those files. With
          the app, you can easily generate new sheets and store relevant
          information, streamlining the process of managing and organizing their
          data. The app uses the user’s email for identification, while the
          user's name and profile picture are displayed to personalize the
          experience. This focused functionality makes the app a simple and
          effective tool for managing user-specific data in Google Sheets.
        </p>

        <p>
          <a style={{ color: "#000", textDecoration: "none" }}>
            Link to Privacy Policy:
          </a>
          <a
            href="https://add-on.tools/privacy"
            style={{ color: "#0c93e4", textDecoration: "none" }}
          >
            Click here.
          </a>
        </p>

        <h2 style={{ marginTop: "40px", fontSize: "1.5rem", color: "#000" }}>
          Describe the content, context, or connection to the app
        </h2>
        <p style={{ color: "#000" }}>
          The tool fetches order information from TikTok Shop. The TTS Extension
          helps you automatically store order details from your own shop. This
          functionality enhances the user's ability to track and manage orders
          efficiently, making it easier to organize and access shop data
          directly through the app.
        </p>

        <hr
          style={{
            border: "0",
            height: "1px",
            background: "#333",
            margin: "30px 0",
          }}
        />

        <h2 style={{ marginTop: "40px", fontSize: "1.5rem", color: "#000" }}>
          Google OAuth2 Limited Use Disclosure
        </h2>
        <p style={{ color: "#000" }}>
          Our app’s use of information received from Google APIs will adhere to
          the{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            style={{ color: "#000", textDecoration: "none" }}
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements.
        </p>

        <h2 style={{ marginTop: "40px", fontSize: "1.5rem", color: "#000" }}>
          Copyright
        </h2>
        <p style={{ color: "#000" }}>
          If you have a copyright complaint, please tell me and include the TTS
          Extension App page that contains the alleged content, identification
          of the work claimed to have been infringed including the name and
          reply email address of the copyright holder/representative, an
          assertion that the use of the material is not authorized and an
          assertion that you are the copyright holder/representative.
        </p>
      </main>
    </div>
  );
}

export default About;
