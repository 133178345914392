import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ShowModal from "./ShowModal";

function CallBack() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sheetId, setSheetId] = useState("");
  useEffect(async () => {
    const code = decodeURIComponent(searchParams.get("code"));
    await fetch(`https://add-on.tools/api/auth/exchange`, {
      // await fetch(`http://localhost:8080/api/auth/exchange`, {
      method: "POST",
      headers: { Authorization: `Bear ${code}` },
      credentials: "include",
    })
      .then((response) => response.json()) // Chuyển đổi response thành JSON
      .then((data) => {
        setSheetId(data.sheetId);
      });
  }, []);

  return <ShowModal sheetId={sheetId}></ShowModal>;
}

export default CallBack;
