function Header() {
  return (
    <header>
      <img className="headerbg" src="/TCBC_005-scaled.jpeg"></img>
      <p>
        Tool lấy thông tin đơn hàng từ tiktok shop, TTS Extension giúp bạn tự
        động lưu trữ thông tin đơn hàng từ chính shop của bạn
      </p>
      <section>
        <p>
          Chúng tôi cam kết minh bạch trong việc thu thập và sử dụng dữ liệu
          người dùng. Dưới đây là những thông tin chi tiết:
        </p>

        <h3>Dữ liệu nào được thu thập?</h3>
        <ul>
          <li>
            <strong>Tên và địa chỉ email:</strong> Được sử dụng để tạo tài khoản
            và cá nhân hóa trải nghiệm của bạn.
          </li>
          <li>
            <strong>Hồ sơ công khai Google:</strong> Được sử dụng để hiển thị
            tên và hình ảnh hồ sơ của bạn trong ứng dụng.
          </li>
          <li>
            <strong>Lịch sử hoạt động trong ứng dụng:</strong> Được thu thập để
            cung cấp các tính năng như đồng bộ hóa dữ liệu và báo cáo tiến độ.
          </li>
        </ul>

        <h3>Tại sao chúng tôi thu thập dữ liệu này?</h3>
        <ul>
          <li>
            Đảm bảo rằng bạn có thể truy cập vào ứng dụng một cách an toàn.
          </li>
          <li>
            Cá nhân hóa trải nghiệm người dùng, giúp ứng dụng phù hợp với nhu
            cầu của bạn.
          </li>
          <li>
            Cung cấp các tính năng như thông báo theo thời gian thực, đồng bộ
            hóa dữ liệu giữa các thiết bị.
          </li>
          <li>Phân tích hiệu suất để cải thiện chất lượng dịch vụ.</li>
        </ul>

        <h3>Chúng tôi không bao giờ làm gì với dữ liệu của bạn?</h3>
        <ul>
          <li>
            Chúng tôi <strong>không bán</strong> hoặc chia sẻ dữ liệu của bạn
            với bên thứ ba cho mục đích thương mại.
          </li>
          <li>
            Chúng tôi <strong>không sử dụng</strong> dữ liệu của bạn cho bất kỳ
            mục đích nào không được bạn đồng ý.
          </li>
        </ul>

        <h3>Chính sách bảo mật</h3>
        <p>
          Dữ liệu của bạn được mã hóa và bảo vệ bởi các tiêu chuẩn bảo mật cao
          nhất.<br></br>
          Bạn có thể xem chi tiết trong
          <a
            href="https://add-on.tools/term"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chính sách bảo mật của chúng tôi
          </a>
          .
        </p>
      </section>
    </header>
  );
}
export default Header;
