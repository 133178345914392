import React from "react";
import Home from "../Home";
import CallBack from "../CallBack";
import Test from "../Test";
import Privacy from "../Privacy";
import Term from "../Term";
import About from "../components/About";
const routes = [
  {
    exact: true,
    path: "/",
    component: Test,
    layout: React.Fragment,
  },
  // quy dinh path nào sẽ chỉ đến component nào (.jsx)
  { exact: true, path: "/", component: Test },
  { exact: true, path: "/oauth/callback", component: CallBack },
  { exact: true, path: "/privacy", component: Privacy },
  { exact: true, path: "/term", component: Term },
  { exact: true, path: "/about", component: About },
  // { exact: true, path: "/table", component: CloneFor },
];

export default routes;
