import { Link, animateScroll as scroll } from "react-scroll";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">TTS Extension</h1>
                <p className="footer-text"></p>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Liên kết</p>
                <ul>
                  <li href="https://add-on.tools/privacy">
                    <Link
                      spy={true}
                      smooth={true}
                      duration={1000}
                      to="/privacy"
                    >
                      {" "}
                      Điều khoản bảo mật{" "}
                    </Link>
                  </li>
                  <li href="https://add-on.tools/term">
                    <Link to="/term" spy={true} smooth={true} duration={1000}>
                      {" "}
                      Chính sách quyền riêng tư{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="contact" spy={true} smooth={true} duration={1000}>
                      {" "}
                      Liên hệ{" "}
                    </Link>
                  </li>
                  <li href="https://add-on.tools/about">
                    <Link to="/term" spy={true} smooth={true} duration={1000}>
                      {" "}
                      Về chúng tôi{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Liên hệ</p>
                <ul>
                  <li>
                    <Link to="#">giapdt@gmai.com</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop">
        <i className="fas fa-level-up-alt"></i>
      </button>
    </footer>
  );
}
export default Footer;
