import AuthenModal from "./AuthenModal";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Header from "./components/Header";

function Test() {
  return (
    <>
      <Navbar />
      <Header />
      <AuthenModal></AuthenModal>
      <Footer />
    </>
  );
}
export default Test;
